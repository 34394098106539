import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css"; //bootstrap link its important
import Topbar from "./component/Header/Topbar";
import Home from "./component/screens/Home";
import Shop from "./component/screens/Shop";
import About from "./component/screens/About";
import Footer from "./component/Header/Footer";
import NotFoundPage from "./component/NotFoundPage/NotFoundPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Main from "./component/ShopCategoryComponent/Main";
import JoinUs from "./component/screens/JoinUs";
import ProductDetail from "./component/screens/ProductDetail";
import Login from "./component/screens/Login";
import Register from "./component/screens/Register";
import ForgetPassword from "./component/screens/ForgetPassword";
import ConfirmPassword from "./component/screens/ConfirmPassword";
import Otp from "./component/screens/Otp";
import OtpForgot from "./component/screens/OtpForgot"
import PreferredCustomer from "./component/screens/PreferredCustomer";
import ResetPassword from "./component/screens/ResetPassword";
import SignupAndSigninTab from "./component/screens/SignupAndSigninTab";
import AddToCart from "./component/screens/AddToCart"
import Checkout from "./component/screens/checkout"
import 'aos/dist/aos.css';
import Dashboard from "./component/screens/Dashboard";
import DashboardDetail from "./component/screens/DashboardDetail";
import DashboardOrder from "./component/screens/DashboardOrder"
import JoinAsAMember from "./component/screens/JoinAsAMember"
import SelectMaxPack from "./component/screens/SelectMaxPack"
import ShopNew from "./component/screens/ShopNew";
import AddToCartGuest from "./component/screens/AddToCartGuest";
import CheckoutGuest from "./component/screens/CheckoutGuest";
import axios from "axios";
import ShopNewMember from "./component/shopNewMember/ShopNewMember";
import BecomeMemberCart from "./component/becomeMemberCart/BecomeMemberCart";
import OderSucessPage from "./component/oderSucessPage/OderSucessPage";
import MemberDaskboard from "./component/screens/MemberDaskboard";
import MemberDashboardOrder from "./component/screens/MemberDashboardOrder";
import MemberDaskboardOrderDetail from "./component/screens/MemberDaskboardOrderDetail";
import MemberDashboardReferral from "./component/screens/MemberDashboardReferral";
import 'react-modern-drawer/dist/index.css'
import { Button, Modal, ToastContainer } from "react-bootstrap";
import CookiePolicy from "./component/screens/CookiePolicy";
import PrivacyNotice from "./component/screens/PrivacyNotice";
import ShopMember from "./component/shopNewMember/ShopMember";
import ReturnRefundPolicy from "./component/screens/ReturnRefundPolicy";
import MemberResetPassword from "./component/screens/MemberResetPassword";
import DashboardResetPassword from "./component/DashboardResetPassword/DashboardResetPassword";
import MemberDashboardTraningAndTools from "./component/screens/MemberDashboardTraningAndTools";
import Tickets from "./component/screens/Tickets";
import BuyItNowCart from "./component/screens/BuyItNowCart";
import BuyItNowCheckout from "./component/screens/BuyItNowCheckout";
import TicketDetail from "./component/screens/TicketDetail";
import LiveStreaming from "./component/screens/LiveStreaming";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  const [cartCount, setCartCount] = useState(0)
  const [showCart, setShowCart] = useState(false)
  const [products, setProducts] = useState([]);
  const randomUserIdGuest = sessionStorage.getItem("randomUserId")
  const userToken = sessionStorage.getItem('userToken')
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  const fetchGestData = async () => {
    try {
      const token = sessionStorage.getItem("userToken");

      const response = await axios.get(`https://max88backend.testingwebsitelink.com/api/gest_view_order?gest_user_id=${randomUserIdGuest}`, {
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      setProducts(response.data.data.cart);
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  const fetchUserData = async () => {
    try {
      const token = sessionStorage.getItem("userToken");
      const response = await axios.get('https://max88backend.testingwebsitelink.com/api/view_order', {
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      setProducts(response.data.data.cart);
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  const getCartCount = async (click) => {
    const randomUserId = sessionStorage.getItem('randomUserId')
    const userId = sessionStorage.getItem('userId')
    if (userId) {
      try {
        const response = await axios.get(
          `https://max88backend.testingwebsitelink.com/api/cart_count?user_id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        setCartCount(response?.data?.data)
        if (click === 'click') {
          setShowCart(true)
          fetchUserData()
        }
      } catch (error) {
        handleShow(error?.response?.data?.message);
      }
    }
    else if (randomUserId) {
      try {
        const response = await axios.get(
          `https://max88backend.testingwebsitelink.com/api/gest_cart_count?gest_user_id=${randomUserId}`
        );
        setCartCount(response?.data?.data)
        if (click === 'click') {
          setShowCart(true)
          fetchGestData()
        }
      } catch (error) {
        handleShow(error?.response?.data?.message);
      }
    }
    else { }
  }

  useEffect(() => { getCartCount('auto') }, [])
  

  return (
    <div className="App">
      <ToastContainer
				position="top-right"
				autoClose={2000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
      <Topbar cartCount={cartCount} showCart={showCart} setShowCart={setShowCart} products={products} setCartCount={setCartCount} />
      <ScrollToTop />
      <>
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/privacy-notice" element={<PrivacyNotice />} />
          <Route path="/shop" element={<Shop getCartCount={getCartCount} />} />
          <Route path="/about" element={<About />} />
          <Route path="/shopcategory" element={<Main />} />
          <Route path="/joinus" element={<JoinUs />} />
          <Route path="/productdetail/:id" element={<ProductDetail getCartCount={getCartCount} />} />
          <Route path="/ticketdetail/:id" element={<TicketDetail getCartCount={getCartCount} />} />
          <Route path="/Signin" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotpassword" element={<ForgetPassword />} />
          <Route path="/confirmpassword" element={<ConfirmPassword />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/preferredcustomer" element={<PreferredCustomer />} />
          <Route path="/otp/:emailverification" element={<Otp />} />
          <Route path="/otpforgot/:emailverification" element={<OtpForgot />} />
          <Route path="/resetpaswword/:emailverification" element={<ResetPassword />} />
          <Route path="/login" element={<SignupAndSigninTab />} />
          <Route path="/cart" element={<AddToCart getCartCount={getCartCount} />} />
          <Route path="/guest-cart" element={<AddToCartGuest />} />
          <Route path="/checkout" element={<Checkout getCartCount={getCartCount} setShowCart={setShowCart} />} />
          <Route path="/checkout-guest" element={<CheckoutGuest getCartCount={getCartCount} setShowCart={setShowCart} />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard-member" element={<MemberDaskboard />} />
          <Route path="/dashboard-order" element={<DashboardOrder />} />
          <Route path="/dashboard-member-order" element={<MemberDashboardOrder />} />
          <Route path="/dashboard-details" element={<DashboardDetail />} />
          <Route path="/dashboard-member-details" element={<MemberDaskboardOrderDetail />} />
          <Route path="/dashboard-member-referral" element={<MemberDashboardReferral />} />
          <Route path="/dashboard-reset-password" element={<MemberResetPassword />} />
          <Route path="/dashboard-traning-tools" element={<MemberDashboardTraningAndTools/>} />
          <Route path="/customer-reset-password" element={<DashboardResetPassword />} />
          <Route path="/joinasamember" element={<JoinAsAMember />} />
          <Route path="/selectmaxpack" element={<SelectMaxPack />} />
          <Route path="/shopnew" element={<ShopNew getCartCount={getCartCount} />} />
          <Route path="/new-member-shop" element={<ShopNewMember getCartCount={getCartCount} />} />
          <Route path="/member-shop" element={<ShopMember getCartCount={getCartCount} />} />
          <Route path="/member-cart" element={<BecomeMemberCart getCartCount={getCartCount} />} />
          <Route path="/oder-success" element={<OderSucessPage />} />
          <Route path="/return-policy" element={<ReturnRefundPolicy />} />
          {/* <Route path="/ticket" element={<Tickets />} /> */}
          <Route path="/but-it-now-cart" element={<BuyItNowCart />} />
          <Route path="/but-it-now-checkout" element={<BuyItNowCheckout />} />
          <Route path="/live-streaming" element={<LiveStreaming />} />
          <Route component={NotFoundPage} />
        </Routes>
      </>
      <Footer />
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default App;
