import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate ,useLocation } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate()
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const ref = params.get('ref');
  const [packageData, setPackageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  const fetchPackages = async () => {
    try {
      const response = await axios.get(
        "https://max88backend.testingwebsitelink.com/api/package"
      );

      setPackageData(response?.data?.data);
      setIsLoading(false)
    } catch (error) {
      handleShow(error?.response?.data?.message);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    fetchPackages()
  }, [])

  const role = sessionStorage.getItem('role')
  const moveToShop = (data) => {
    if(role === 'member'){
      navigate(`/member-shop?id=${data?.id}&price=${data.price}&name=${data.tittle}`)
    }else{
      if(ref !== '' && ref !== null && ref !== undefined){
        navigate(`/new-member-shop?id=${data?.id}&price=${data.price}&name=${data.tittle}&ref=${ref}`)
      }else{
        navigate(`/new-member-shop?id=${data?.id}&price=${data.price}&name=${data.tittle}`)
      }
    }
  }

  const userId = sessionStorage.getItem('userId')

  useEffect(() => {
    if (userId && role !== 'member') {
      navigate('/')
    } else {
      setIsLoading(false)
    }
  }, []);

  // useEffect(() => {
  //   const disableBack = (e) => {
  //     e.preventDefault();
  //     window.history.forward(); // This will keep pushing the user forward
  //   };

  //   window.history.pushState(null, null, window.location.pathname); // Ensure back button does not take the user back
  //   window.addEventListener('popstate', disableBack);

  //   return () => window.removeEventListener('popstate', disableBack);
  // }, []);


  return (
    <>
      {isLoading ?
        <div className="loader-container text-center loader-container-shop">
          <Spinner animation="border" role="status"></Spinner>
        </div>
        :
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="register-left-div ">
                <div>
                  <img
                    width={60}
                    src={require("../assets/logo/f-logo.png")}
                    alt="Google"
                  />
                </div>
                <div>
                  <h4 className="logo-text-b">MAXIMUM 88</h4>
                  <h4 className="logo-text-2-b">CORPORATION</h4>
                </div>
              </div>
            </div>
            <div className="mt-4 select-left-img-div-1">
              <div className="">
                <h2>Great Job for taking action! Select to START!</h2>
              </div>
              {/* <div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div> */}
            </div>
            {/* <div className="col-lg-6">
            <div className="login-left-img-div-2-d-fle">
              <div className="mt-4 login-left-img-div-2 login-left-img-div-2-main">
                <div className="login-left-img-div-2-main-img">
                  <img
                    src={require("../assets/images/maxpack1.png")}
                    alt="Google"
                  />
                </div>
                <div className="starter-red-top-div">
                  <div className="starter-red-top-bn">
                    <h5>STARTER</h5>
                    <p>PACKAGE</p>
                  </div>
                  <div className="">
                    <p>
                      This exquisite blend combines the nutritional powerhouses
                      of Acai Berry, Buah Merah, Guaynabo,
                    </p>
                    <FaArrowRightLong className="starter-red-top-div-h3-icon" />
                  </div>
                  <div className="starter-red-top-div-h3-div">
                    <h3 children="starter-red-top-div-h3">$70</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            {isLoading ? (
              <div className="loader-container text-center loader-container-shop">
                <Spinner animation="border" role="status"></Spinner>
              </div>) :
              packageData?.map((data, index) => {
                return (
                  <div key={index} className="col-lg-6" onClick={() => { moveToShop(data) }}>
                    <div className="login-left-img-div-2-d-fle">
                      <div className="mt-4 login-left-img-div-2 login-left-img-div-2-main">
                        <div className="login-left-img-div-2-main-img">
                          <img
                            src={data?.image}
                            alt={data?.tittle}
                            width={100}
                          />
                        </div>
                        <div className="starter-red-top-div">
                          <div className="starter-red-top-bn">
                            <h5>{data?.tittle}</h5>
                            <p>PACKAGE</p>
                          </div>
                          <div className="maxer">
                            <p>
                              {data?.description}
                            </p>
                            <div style={{ textAlign: 'end', padding: '0px 27px' }}>
                              <FaArrowRightLong className="starter-red-top-div-h3-icon" />
                            </div>
                          </div>
                          <div className="starter-red-top-div-h3-div">
                            <h3 children="starter-red-top-div-h3">${data?.price}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })

            }

          </div>
        </div>
      }
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Main;
