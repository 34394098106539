import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Button, Modal } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import SideMenu from "../SideMenuComponent/SideMenu";

const TicketDetail = ({ getCartCount }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [addedProduct, setAddedProduct] = useState(null);
    const role = sessionStorage.getItem("role");

    useEffect(() => {
        AOS.init();
    }, []);

    const userId = sessionStorage.getItem("userId");
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [productImages, setProductImages] = useState([]);
    const [mainImage, setMainImage] = useState("");
    const [num, setNum] = useState(1);

    const [showPopup, setShowPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleShow = (message) => {
        setErrorMessage(message);
        // setShowPopup(true);
    };

    const handleClose = () => setShowPopup(false);

    function matchAndSetQuantity(array1, array2) {
        const map = {};
        array2.forEach(item => {
            map[item.product_id] = item.quantity;
        });

        const result = array1.map(item => ({
            ...item,
            quantity: map[item.id] || 0
        }));
        setProduct(result)
        // setNum(result?.[0]?.quantity)
    }

    const fetchProductById = async () => {
        try {
            const token = localStorage.getItem("ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE");
            const response = await axios.get(
                `https://max88backend.testingwebsitelink.com/api/ticket/${id}`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const productData = response.data.ticket;
            fetchCartData([productData])
            setProductImages(productData.image);
            setMainImage(productData?.image?.[0]);
        } catch (error) {
            handleShow(error?.response?.data?.message);
        }
    };
    useEffect(() => {

        fetchProductById();
    }, [id]);

    const incNum = () => {
        if (num < 10) {
            setNum(Number(num) + 1);
        }
    };

    const decNum = () => {
        if (+num > 1) {
            setNum(num - 1);
        }
    };

    const handleChange = (e) => {
        setNum(e.target.value);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleAddToCart = async (url, data) => {
        try {
            const token = sessionStorage.getItem("userToken");
            const response = await axios.post(url, data, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            getCartCount('click');
            setAddedProduct(product);
            setIsMenuOpen(true);
        } catch (error) {
            handleShow(error?.response?.data?.message);
        }
    };

    const addToCart = () => {
        handleAddToCart("https://max88backend.testingwebsitelink.com/api/add_to_cart", {
            quantity: num,
            product_id: id,
        });
    };

    const generateUserId = () => {
        const randomUserId = Math.floor(Math.random() * 1000000);
        sessionStorage.setItem("randomUserId", randomUserId);
        return randomUserId;
    };

    const randomUserIdGuest = sessionStorage.getItem("randomUserId") || generateUserId();

    const addToCartGuest = () => {
        handleAddToCart("https://max88backend.testingwebsitelink.com/api/gest_add_to_cart", {
            quantity: num,
            product_id: id,
            gest_user_id: randomUserIdGuest,
        });
    };

    const customPagination = {
        renderBullet: function (index, className) {
            return `<span className="${className} paginationBullet" style="background-color: ${index === this.activeIndex ? "#c6282d" : "#c6282d"};"></span>`;
        },
    };

    const parseDescription = (description) => {
        const cleanedDescription = description.replace(/| \|/g, '');
        const parts = cleanedDescription.split(/(<li>.*?<\/li>)/g);

        return parts.map((part, index) => {
            if (part.startsWith("<li>")) {
                return <li key={index} dangerouslySetInnerHTML={{ __html: part }} />;
            } else {
                return <p key={index} style={{ fontSize: '1.1em', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: part }}></p>;
            }
        });
    };

    const descriptionItems = product.long_description ? parseDescription(product.long_description) : [];

    const processDescription = (description) => {
        return description
            .replace(/<\/?b>/g, '')
            .split('|')
            .map((part, index) => (
                <React.Fragment key={index}>
                    <strong>{part.trim()}</strong>
                    {index < description.split('|').length - 1 && <br />}
                </React.Fragment>
            ));
    };

    const processDescriptionpara = (description) => {
        return description
            .replace(/<\/?b>/g, '')
            .split('|')
            .map((part, index) => (
                <React.Fragment key={index}>
                    {part.trim()}
                    {index < description.split('|').length - 1 && <br />}
                </React.Fragment>
            ));
    };

    const fetchCartData = async (ProductList) => {
        if (userId) {
            try {
                const token = sessionStorage.getItem("userToken");
                const response = await axios.get('https://max88backend.testingwebsitelink.com/api/view_order', {
                    headers: {
                        'Accept': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });
                matchAndSetQuantity(ProductList, response.data.data.cart)
            } catch (error) {
                handleShow(error?.response?.data?.message);
            }
        } else {
            try {
                const token = sessionStorage.getItem("userToken");
                const response = await axios.get(`https://max88backend.testingwebsitelink.com/api/gest_view_order?gest_user_id=${randomUserIdGuest}`, {
                    headers: {
                        'Accept': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });
                matchAndSetQuantity(ProductList, response.data.data.cart)
            } catch (error) {
                handleShow(error?.response?.data?.message);
            }
        }
    };
    const navigate = useNavigate()
    const addTicketToCart = async (id) => {
        try {
            const token = sessionStorage.getItem("userToken");
            const response = await axios.post(
                "https://max88backend.testingwebsitelink.com/api/buynow",
                {
                    quantity: num,
                    ticket_id: id,
                    user_id: randomUserIdGuest,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            navigate('/but-it-now-cart')
        } catch (error) {
            handleShow(error?.response?.data?.message);
        }
    };


    return (
        <>
            <Container>
                <div className="row productDetailsCon">
                    <div className="col-lg-6">
                        <div className="row productViewDivRow">
                            <div className="col-lg-12">
                                <img
                                    className="img-obj-wid img-obj-wid-m"
                                    src={mainImage}
                                    alt="Product"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 productDetailstextDiv">
                        <div className="productDetailstextDivHEad" data-aos="fade-up">
                            <div>
                                <h2>{product?.[0]?.title}</h2>
                            </div>
                        </div>
                        <p style={{ marginTop: '20px' }}>
                            {product?.[0]?.description
                                ? processDescriptionpara(`${product?.[0]?.description}`)
                                : 'No description available'}
                        </p>
                        <div className="productDetailstextDivHEadH4">
                            <h5>${product?.[0]?.price}</h5>
                        </div>
                        <div className="productDetailstextDivHeadQuan">
                            <div className="price">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <button className="btn" type="button" onClick={decNum}>
                                            -
                                        </button>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={num}
                                        onChange={handleChange}
                                    />
                                    <div className="input-group-prepend">
                                        <button className="btn" type="button" onClick={incNum}>
                                            +
                                        </button>
                                    </div>
                                </div>
                                <div className="productDetailstextDivHEadButton">
                                    <Button variant="danger" onClick={() => { addTicketToCart(product?.[0]?.id) }}> BUY IT NOW</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row productDetailAboutCon">
                    <div className="col-lg-12 productDetailsDescription">
                        <div className="row productDetailsDescriptionRow">
                            <div className="product-detail-desc ticket-detail-desc" dangerouslySetInnerHTML={{ __html: product?.[0]?.long_description }}></div>
                            <div className="col-12 product-details-div">
                                <h5>{product?.[0]?.lastText}</h5>
                            </div>
                            <div className="col-12 product-details-div">
                                <p>{product?.[0]?.note}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </Container>
            {/* <SideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} addedProduct={addedProduct} /> */}
            <Modal show={showPopup} onHide={handleClose} className="error-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        className="error-button-close"
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TicketDetail;
