import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const BuyItNowCart = () => {

  const randomUserIdGuest = sessionStorage.getItem("randomUserId")
  const [products, setProducts] = useState([]);
  const [price, setPrice] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);


  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("userToken");

      const response = await axios.get(`https://max88backend.testingwebsitelink.com/api/buynow/list?user_id=${randomUserIdGuest}`, {
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      setIsLoading(false);
      setProducts(response.data.data.cart);
      setPrice(response.data.data);
    } catch (error) {
      setIsLoading(false);
      handleShow(error?.response?.data?.message);
    }
  };

  const handleIncrement = async (cart) => {
    const token = sessionStorage.getItem("userToken");
    try {
      const response = await axios.post(
        "https://max88backend.testingwebsitelink.com/api/buynow/update-quantity",
        {
          quantity: cart.quantity + 1,
          id: cart.id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchData()

    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  const handleDecrement = async (cart) => {
    if (cart.quantity > 1) {
      const token = sessionStorage.getItem("userToken");
      try {
        const response = await axios.post(
          "https://max88backend.testingwebsitelink.com/api/buynow/update-quantity",
          {
            quantity: cart.quantity - 1,
            id: cart.id
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchData()
      } catch (error) {
        handleShow(error?.response?.data?.message);
      }
    }
  };

  const handleRemove = async (e, cartId) => {
    try {
      e.preventDefault();
      const token = sessionStorage.getItem("userToken");
      const response = await axios.post(
        "https://max88backend.testingwebsitelink.com/api/buynow/delete",
        {
          id: cartId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      window.location.reload();
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="cart-banner-text">
        <p>
          Discover the best you, inside and out, with our integrated beauty and
          wellness products.
        </p>
      </div>

      {isLoading ? (
        <div className="loader-container text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="new-chackout">
          <div className="new-chackout-wrap">
            <div className="container">
              <div className="row">
                <div className="col-lg-9">
                  <div className="left">
                    {products?.length === 0 ? (
                      <h2 className="no-data-found">No Ticket available</h2>
                    ) : (
                      <>
                        <div className="title-count">Your Cart <span>({products?.length} items)</span></div>
                        <div className="products-list">
                          <ul>
                            {products?.map((product, index) => {
                              return (
                                <li key={index}>
                                  <div className="products-list-left">
                                    <img
                                      src={product?.ticket?.image?.[0]}
                                      alt={product?.ticket?.title}
                                    />
                                  </div>
                                  <div className="products-list-right">
                                    <div className="one">
                                    {product?.ticket?.title}
                                    </div>
                                    <div className="two">
                                      <span>Item #</span>000{product?.id}
                                    </div>
                                    <div className="three">
                                      $ {product?.ticket?.price}
                                      <span>In Stock</span>
                                    </div>
                                    <div className="four">
                                      <div className="four-left">
                                        <ul>
                                          <li onClick={() => handleDecrement(product)}><AiOutlineMinus /></li>
                                          <li>{product?.quantity}</li>
                                          <li onClick={() => handleIncrement(product)} ><AiOutlinePlus /></li>
                                        </ul>
                                        <span onClick={(e) => handleRemove(e, product?.id)}>
                                          <MdDeleteOutline />
                                        </span>
                                      </div>
                                      <div className="four-right">
                                        <p>${product?.total_price}</p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </>)
                    }
                  </div>


                </div>
                <div className="col-lg-3">
                  <div className="right">
                    <div className="r-top">
                      <h2>Order Summary</h2>
                    </div>
                    <div className="t-botton">
                      <div className="right-1">
                        <ul>
                          <li>Subtotal</li>
                          <li>${price.finalPrice}</li>
                        </ul>
                      </div>
                      <hr />
                      <div className="right-1">
                        <ul>
                          <li>Tax</li>
                          <li>${price.tax}</li>
                        </ul>
                      </div>
                      <hr />
                      <div className="right-3">
                        <ul>
                          <li>Estimated Total</li>
                          <li>${(+price.finalPrice)+(+price.tax)}</li>
                        </ul>
                      </div>
                      <div className="right-4">
                        {/* <Link to='/login'>
                          <button>
                            Sign in
                          </button>
                        </Link> */}
                        {price.finalPrice === "0.00" ? (
                          null
                        ) : (
                          <Link to='/but-it-now-checkout'>
                            <button>
                              Checkout
                            </button>
                          </Link>

                        )}
                      </div>
                      <div className="right-5">
                        <p>Exact discounts, promotions, delivery charges, taxes and other fees will be calculated during checkout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BuyItNowCart;
